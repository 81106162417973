import React from "react";
import { HeadTitle } from "src/components/MandalaUI/HeadTitle";
import { ErrorPageComponent } from "src/ComponentPage/ErrorPageComponent";

const FourOFourPage = () => (
  <React.Fragment>
    <HeadTitle title="Page not Found" />
    <ErrorPageComponent is404={true} />;
  </React.Fragment>
);

export default FourOFourPage;
