import React from "react";
import { Flex, Box, Heading, Text, Button } from "@chakra-ui/react";
import { useRouter } from "next/router";

export const ErrorPageComponent = ({ is404 = false }) => {
  const { push } = useRouter();

  const errorTitle = is404
    ? "Oops, We couldn't find that page"
    : "An error has occured...";

  const errorMessage = is404
    ? "It seems the URL you’re looking for doesn’t exist"
    : "Please try refreshing your browser. If the problem still exists, please contact administrator for more support";

  return (
    <Flex justify="center" align="center" w="100%" h="100vh" flexDir="column">
      <Box fontSize="4.5em">delman.io</Box>
      <Flex
        mt="1em"
        bg="mandala.black.100"
        maxW="40%"
        flexDir="column"
        p="1.5em"
        borderRadius=".25em"
        border="solid 1px"
        borderColor="mandala.black.300"
      >
        <Heading variant="h3">{errorTitle}</Heading>
        <Text mt="1em">{errorMessage}.</Text>
        <Flex mt="1em">
          <Button variant="link" bg="transparent" onClick={() => push("/home")}>
            Back to Home
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
